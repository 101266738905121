<template>
  <a-modal
    v-model:visible="store.getters.settings.isModalLogin"
    :title="null"
    :footer="null"
    class="modal-landing"
  >
  <div class="logo-mdxl-modal">
    <img src="@/assets/images/landing-page/logo_mdxl_color.png" alt="logo mdxl" />
  </div>
    <a-form
      :model="loginForm"
      :rules="rules"
      layout="vertical"
      class="form-login"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item name="email" class="email">
        <a-input
          v-model:value="loginForm.email"
          placeholder="Email / Username"
        >
        <template #prefix>
          <img src="@/assets/images/icon/ic_store.svg" alt="logo mdxl" />
        </template>
        </a-input>

      </a-form-item>
      <a-form-item name="password" class="password">
        <a-input-password
          v-model:value="loginForm.password"
          placeholder="Password"
          type="password"
        >
        <template #prefix>
          <img src="@/assets/images/icon/ic_lock.svg" alt="logo mdxl" />
        </template>
      </a-input-password>
      </a-form-item>
      <div class="remember-me">
        <input type="checkbox" value=" IngatSaya" />
        <label for="">Ingat Saya</label>
      </div>
      <div class="login-button mt-3">
        <div @click="handleFinish" class="tombol w-100 btn-submit" :loading="loading">
          <strong>Sign in</strong>
        </div>
      </div>
      <p class="text-danger ml-2" v-if="showError" style="margin-top: 10px; text-align: left;">
        Gagal login, pastikan username dan password benar!
      </p>
      <div class="forgot-password" @click="goToForgetPassword()">
        <p class="text-primary password">
          Lupa Password
        </p>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { UserOutlined } from '@ant-design/icons-vue';
import {  useRouter } from 'vue-router'
import { Base64Encode } from '@/helpers/utils'

const store = useStore()
const router = useRouter()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const showError = ref(false)
    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Please input password!', trigger: 'change' }],
    }
    const loginForm = reactive({
      email: '',
      password: '',
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = () => {
      store
        .dispatch('user/LOGIN', {
          payload: {
            email: loginForm.email,
            password: Base64Encode(loginForm.password),
          },
        })
        .then(() => {
          showError.value = false
        })
        .catch(error => {
          console.log('Uh oh error : ', error)
          showError.value = true
        })
    }
    const handleFinishFailed = errors => {
      console.log('handleFinishFailed', errors)
      showError.value = false
    }
    const goToForgetPassword = () => {
      // /auth/forgot-password
      router.push('/auth/forgot-password')
      store.getters.settings.isModalLogin = false
    }
</script>

<style lang="scss" >
@import './style/scss/variables.scss';
@import './style/scss/index.scss';
.modal-landing{
  .ant-modal-content{
    border-radius: 18px !important;
  }
}

</style>

